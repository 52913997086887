import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col, Card, Spinner } from "react-bootstrap";
import { CircularProgress } from "@material-ui/core";
import styled from "styled-components";
import moment from "moment";
import { getParameterByName } from "../../../Utils/location";
import {
  apiGetAssignedVolunteer,
  apiResponseAssignedVolunteer,
} from "../../../datasources/volunteerRequestRepo";

const CONTAINER = styled.div`
  .error {
    border: 1px solid #ff6565;
  }
`;

export function InvitationVolunteerRequestPage(props) {
  const [idEvent, setIdEvent] = useState(null);
  const [dataEvent, setDataEvent] = useState(null);
  const [responseAct, setResponseAct] = useState(null);
  const [formState, setFormState] = useState({
    loading: false,
    error: false,
    errorMessage: null,
  });

  useEffect(() => {
    let idEvent = getParameterByName("id");
    setIdEvent(idEvent);
    getAssignedVolunteer(idEvent);
  }, []);

  function getAssignedVolunteer(idEvent) {
    setFormState({
      ...formState,
      loading: true,
    });
    apiGetAssignedVolunteer({ id: idEvent })
      .then((res) => {
        console.log(res);
        setDataEvent(res.data.assignedVolunteer);
        setFormState({
          ...formState,
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        setFormState({
          ...formState,
          loading: false,
          error: true,
          errorMessage: err?.response?.message,
        });
      });
  }

  function submitData(status) {
    setResponseAct(status);
    let payload = {
      id: idEvent,
      invitationResponse: status,
    };
    setFormState({
      ...formState,
      loading: true,
    });
    apiResponseAssignedVolunteer(payload)
      .then((res) => {
        setFormState({
          ...formState,
          loading: false,
        });
        getAssignedVolunteer(idEvent);
        console.log(res);
      })
      .catch((err) => {
        setFormState({
          ...formState,
          loading: false,
          error: true,
          errorMessage: err?.response?.message,
        });
        console.log(err);
      });
  }

  return (
    <div
      className="d-flex align-item-center justify-content-center"
      style={{
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          height: "100vh",
          width: "100vw",
          backgroundImage: `url(assets/images/hold_heart.png)`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          filter: "blur(8px)",
          position: "absolute",
        }}
      />
      <Card style={{ width: "45rem", height: "25rem" }}>
        <Card.Header>
          <div className="col-lg-12 justify-content-center align-items-center d-flex">
            <div className="col-lg-2">
              <img
                alt="Logo"
                src={"assets/images/logo-interaktiv.png"}
                style={{
                  maxHeight: "45px",
                }}
              />
            </div>
            <div className="col-lg-8">
              <h5 className="text-center ">Invitation Volunteer</h5>
            </div>
            <div className="col-lg-2" />
          </div>
        </Card.Header>
        <Card.Body>
          <CONTAINER>
            {dataEvent !== null ? (
              <Form>
                <Form.Group as={Row} className={"mb-1"}>
                  <Col lg={2} xs={0} />
                  <Col lg={4} xs={6}>
                    <Form.Group>
                      <Form.Label style={{ fontWeight: "bold" }}>
                        Name
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={6} xs={6}>
                    <Form.Group>
                      <Form.Label>{dataEvent?.LastName__c}</Form.Label>
                    </Form.Group>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className={"mb-1"}>
                  <Col lg={2} xs={0} />
                  <Col lg={4} xs={6}>
                    <Form.Group>
                      <Form.Label style={{ fontWeight: "bold" }}>
                        Volunteer Request Name
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={6} xs={6}>
                    <Form.Group>
                      <Form.Label>
                        {dataEvent?.Volunteer_Request__r.Name}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className={"mb-1"}>
                  <Col lg={2} xs={0} />
                  <Col lg={4} xs={6}>
                    <Form.Group>
                      <Form.Label style={{ fontWeight: "bold" }}>
                        Start Date
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={6} xs={6}>
                    <Form.Group>
                      <Form.Label>
                        {moment(dataEvent?.StartDateTime).format(
                          "DD MMMM YYYY hh:mm A"
                        )}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className={"mb-1"}>
                  <Col lg={2} xs={0} />
                  <Col lg={4} xs={6}>
                    <Form.Group>
                      <Form.Label style={{ fontWeight: "bold" }}>
                        End Date
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={6} xs={6}>
                    <Form.Group>
                      <Form.Label>
                        {moment(dataEvent?.EndDateTime).format(
                          "DD MMMM YYYY hh:mm A"
                        )}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className={"mb-1"}>
                  <Col lg={2} xs={0} />
                  <Col lg={4} xs={6}>
                    <Form.Group>
                      <Form.Label style={{ fontWeight: "bold" }}>
                        Volunteer Location
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={6} xs={6}>
                    <Form.Group>
                      {dataEvent?.Volunteer_Request__r?.Location_Address__c !==
                        "" &&
                      dataEvent?.Volunteer_Request__r?.Location_Address__c?.includes(
                        "http"
                      ) ? (
                        <a
                          href={
                            dataEvent?.Volunteer_Request__r?.Location_Address__c
                          }
                          target="_blank"
                        >
                          <Form.Label
                            style={{
                              color: "blue",
                              textDecorationLine: "underline",
                              cursor: "pointer",
                            }}
                          >
                            {
                              dataEvent?.Volunteer_Request__r
                                ?.Location_Address__c
                            }
                          </Form.Label>
                        </a>
                      ) : (
                        <Form.Label>
                          {dataEvent?.Volunteer_Request__r?.Location_Address__c
                            ? dataEvent?.Volunteer_Request__r
                                ?.Location_Address__c
                            : "N/A"}
                        </Form.Label>
                      )}
                    </Form.Group>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className={"mb-1"}>
                  <Col lg={2} xs={0} />
                  <Col lg={4} xs={6}>
                    <Form.Group>
                      <Form.Label style={{ fontWeight: "bold" }}>
                        Status
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={6} xs={6}>
                    <Form.Group>
                      <Form.Label>{dataEvent?.Asignee_Status__c}</Form.Label>
                    </Form.Group>
                  </Col>
                </Form.Group>
                {(dataEvent?.Asignee_Status__c === "Contacted" ||
                  dataEvent?.Asignee_Status__c === "Selected") && (
                  <Form.Group
                    as={Row}
                    className={
                      "mb-1 align-items-center justify-content-center mt-3"
                    }
                  >
                    <Button
                      variant={"primary"}
                      style={{
                        width: "auto",
                        maxWidth: "50%",
                        minWidth: "30%",
                      }}
                      onClick={() => submitData("Accepted")}
                      disabled={formState.loading}
                    >
                      Accept
                      {formState.loading && responseAct === "Accepted" && (
                        <Spinner animation="border" role="status" size="sm">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      )}
                    </Button>
                    <Button
                      variant={"danger"}
                      style={{
                        width: "auto",
                        maxWidth: "50%",
                        minWidth: "30%",
                        marginLeft: 30,
                      }}
                      onClick={() => submitData("Rejected")}
                      disabled={formState.loading}
                    >
                      Reject
                      {formState.loading && responseAct === "Rejected" && (
                        <Spinner animation="border" role="status" size="sm">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      )}
                    </Button>
                  </Form.Group>
                )}
              </Form>
            ) : (
              <div
                style={{
                  width: "100%",
                  height: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </div>
            )}
          </CONTAINER>
        </Card.Body>
      </Card>
    </div>
  );
}
