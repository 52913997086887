import { methodService, apiService } from "./apiVolunter";

export const URL = {
  PE: "/programmeEvents",
  PARTICIPANT: "/participants",
};

export const apiGetProgramme = (data) => {
  return apiService(URL.PE + `/${data.id}`, methodService.GET, null, null);
};

export const apiGetParticipant = (data) => {
  return apiService(
    URL.PARTICIPANT + `/${data.id}`,
    methodService.GET,
    null,
    null
  );
};

export const apiResponseParticipant = (data) => {
  return apiService(
    URL.PARTICIPANT + `/${data.id}/responseInvitation`,
    methodService.PATCH,
    data,
    null
  );
};

export const apiCheckinParticipant = (data) => {
  return apiService(
    URL.PE + `/${data.id}/checkin`,
    methodService.PATCH,
    data,
    null
  );
};
