import { methodService, apiService } from "./apiVolunter";

export const URL = {
  ORIENTATION: "/orientations",
};

export const apiGetOrientation = (data) => {
  return apiService(
    URL.ORIENTATION + `/${data.id}`,
    methodService.GET,
    null,
    null
  );
};

export const apiResponseOrientation = (data) => {
  return apiService(
    URL.ORIENTATION + `/${data.id}/responseInvitation`,
    methodService.PATCH,
    data,
    null
  );
};

export const apiCheckinOrientation = (data) => {
  return apiService(
    URL.ORIENTATION + `/${data.id}/checkin`,
    methodService.PATCH,
    data,
    null
  );
};
