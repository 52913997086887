import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col, Card, Spinner } from "react-bootstrap";
import { CircularProgress } from "@material-ui/core";
import styled from "styled-components";
import moment from "moment";
import * as Yup from "yup";
import { getParameterByName } from "../../../Utils/location";
import { apiGetOrientation, apiResponseOrientation } from "../../../datasources/orientationRepo";

const CONTAINER = styled.div`
  .error {
    border: 1px solid #ff6565;
  }
`;

export function InvitationOrientationPage(props) {
  const [idEvent, setIdEvent] = useState(null);
  const [dataEvent, setDataEvent] = useState(null);
  const [responseAct, setResponseAct] = useState(null);
  const [formState, setFormState] = useState({
    loading: false,
    error: false,
    errorMessage: null
  })

  useEffect(() => {
    let idEvent = getParameterByName('id');
    setIdEvent(idEvent);
    getOrientation(idEvent);
  }, []);

  function getOrientation(idEvent){
    setFormState({
      ...formState,
      loading: true
    })
    apiGetOrientation({id: idEvent}).then((res) => {
      console.log(res);
      setDataEvent(res.data);
      setFormState({
        ...formState,
        loading: false
      })
    }).catch(err => {
      console.log(err);
      setFormState({
        ...formState,
        loading: false,
        error: true,
        errorMessage: err?.response?.message
      })
    })
  }

  function submitData(status){
    setResponseAct(status);
    let payload = {
      id: idEvent,
      invitationResponse: status,
    }
    setFormState({
      ...formState,
      loading: true
    })
    apiResponseOrientation(payload).then((res) => {
      setFormState({
        ...formState,
        loading: false
      })
      getOrientation(idEvent);
      console.log(res);
    }).catch(err => {
      setFormState({
        ...formState,
        loading: false,
        error: true,
        errorMessage: err?.response?.message
      })
      console.log(err);
    })
  }

  return (
    <div
      className="d-flex align-item-center justify-content-center"
      style={{
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          height: "100vh",
          width: "100vw",
          backgroundImage: `url(assets/images/hold_heart.png)`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          filter: "blur(8px)",
          position: "absolute",
        }}
      />
      <Card style={{ width: "45rem", height: "25rem"}}>
        <Card.Header>
          <div className="col-lg-12 justify-content-center align-items-center d-flex">
            <div className="col-lg-2">
              <img
                alt="Logo"
                src={'assets/images/logo-interaktiv.png'}
                style={{
                  maxHeight: "45px",
                }}
              />
            </div>
            <div className="col-lg-8">
              <h5 className="text-center ">Orientation Event</h5>
            </div>
            <div className="col-lg-2" />
          </div>
        </Card.Header>
        <Card.Body>
          <CONTAINER>
            {dataEvent !== null ? (
              <Form>
                <Form.Group as={Row} className={"mb-1"}>
                  <Col lg={2} xs={0}/>
                  <Col lg={4} xs={6}>
                    <Form.Group>
                      <Form.Label style={{fontWeight: 'bold'}}>
                        Name
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={6} xs={6}>
                    <Form.Group>
                      <Form.Label>{dataEvent?.Name}</Form.Label>
                    </Form.Group>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className={"mb-1"}>
                  <Col lg={2} xs={0}/>
                  <Col lg={4} xs={6}>
                    <Form.Group>
                      <Form.Label style={{fontWeight: 'bold'}}>
                        Subject
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={6} xs={6}>
                    <Form.Group>
                      <Form.Label>{dataEvent?.Subject}</Form.Label>
                    </Form.Group>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className={"mb-1"}>
                  <Col lg={2} xs={0}/>
                  <Col lg={4} xs={6}>
                    <Form.Group>
                      <Form.Label style={{fontWeight: 'bold'}}>
                        Start Date
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={6} xs={6}>
                    <Form.Group>
                      <Form.Label>
                        {moment(dataEvent?.StartDateTime).format("DD MMMM YYYY hh:mm A")}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className={"mb-1"}>
                  <Col lg={2} xs={0}/>
                  <Col lg={4} xs={6}>
                    <Form.Group>
                      <Form.Label style={{fontWeight: 'bold'}}>
                        End Date
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={6} xs={6}>
                    <Form.Group>
                      <Form.Label>
                        {moment(dataEvent?.EndDateTime).format("DD MMMM YYYY hh:mm A")}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className={"mb-1"}>
                  <Col lg={2} xs={0}/>
                  <Col lg={4} xs={6}>
                    <Form.Group>
                      <Form.Label style={{fontWeight: 'bold'}}>
                        Volunteer Location
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={6} xs={6}>
                    <Form.Group>
                      {
                        dataEvent?.Location !== "" && dataEvent?.Location?.includes("http") ?
                        <a href={dataEvent?.Location} target="_blank">
                          <Form.Label style={{
                            color: 'blue',
                            textDecorationLine: 'underline',
                            cursor: 'pointer'
                          }}>{dataEvent?.Location}</Form.Label>
                        </a> :
                        <Form.Label>{dataEvent?.Location ? dataEvent?.Location : 'N/A'}</Form.Label>
                      }
                    </Form.Group>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className={"mb-1"}>
                  <Col lg={2} xs={0}/>
                  <Col lg={4} xs={6}>
                    <Form.Group>
                      <Form.Label style={{fontWeight: 'bold'}}>
                        Status
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={6} xs={6}>
                    <Form.Group>
                      <Form.Label>
                        {dataEvent?.Status__c}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                </Form.Group>
                {/* <Form.Group 
                  as={Row}
                  className={'align-items-center justify-content-center mb-5'}
                >
                  <Col lg={8} xs={12} className={"align-items-center justify-content-center d-flex"}>
                    <Form.Control 
                      type="text" 
                      placeholder="email" 
                      name="email"
                      id="email"
                      style={{ width: "50%" }}
                      {...register("email")}
                      className={(errors.email) && "error"}
                      disabled={invitationReducer?.invitationCheckin?.data?.event[0].success}
                    />
                  </Col>
                  <Col lg={8} xs={12} className={"align-items-center justify-content-center d-flex"}>
                    <Form.Text className={`${errors.email ? 'text-danger' : 'text-muted'} mt-0 mb-0`}>
                      {
                        errors.email?.message
                      }
                    </Form.Text>
                  </Col>
                </Form.Group>
                <Form.Text 
                  as={Row}
                  className={`${invitationReducer.isError ? 'text-danger' : 'text-muted'} mt-0 mb-0 align-items-center justify-content-center d-flex`}
                >
                  { invitationReducer.errorMsg?.message }
                </Form.Text> */}
                {
                  dataEvent?.Status__c === 'Invitation Sent' &&
                  <Form.Group
                    as={Row}
                    className={"mb-1 align-items-center justify-content-center mt-3"}
                  >
                    <Button
                      variant={"primary"}
                      style={{ width: "auto", maxWidth: "50%", minWidth: "30%" }}
                      onClick={() => submitData("Accepted")}
                      disabled={formState.loading}
                    >
                      Accept
                      {
                        formState.loading && responseAct === "Accepted" &&
                        <Spinner animation="border" role="status" size="sm">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      }
                    </Button>
                    <Button
                      variant={"danger"}
                      style={{ width: "auto", maxWidth: "50%", minWidth: "30%", marginLeft: 30 }}
                      onClick={() => submitData("Declined")}
                      disabled={formState.loading}
                    >
                      Reject
                      {
                        formState.loading && responseAct === "Declined" &&
                        <Spinner animation="border" role="status" size="sm">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      }
                    </Button>
                  </Form.Group>
                }
              </Form>
            ) : (
              <div
                style={{
                  width: "100%",
                  height: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </div>
            )}
          </CONTAINER>
        </Card.Body>
      </Card>
    </div>
  );
}