import { lazy, Suspense } from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { InvitationOrientationPage } from "../src/Modules/Orientation/Pages/InvitationOrientation";
import { AttendanceOrientationPage } from "../src/Modules/Orientation/Pages/AttendanceOrientation";
import { InvitationProgrammeEventsPage } from "../src/Modules/ProgrammeEvents/Pages/InvitationProgrammeEvents";
import { AttendanceProgrammeEventsPage } from "../src/Modules/ProgrammeEvents/Pages/AttendanceProgrammeEvent";
import { InvitationVolunteerRequestPage } from "../src/Modules/VolunteerRequest/Pages/InvitationVolunteerRequest";
import { AttendanceVolunteerRequestPage } from "../src/Modules/VolunteerRequest/Pages/AttendanceVolunteerRequest";
const Donation = lazy(() => import("../src/Modules/Donation/Pages/Donation"));
const Question = lazy(() => import("../src/Modules/Question/Pages/Question"));
const QuestionResult = lazy(() =>
  import("../src/Modules/Question/Pages/QuestionResult")
);
const Upload = lazy(() => import("../src/Modules/Upload/Pages/Upload"));
const DonationList = lazy(() =>
  import("../src/Modules/Donation/Pages/DonationList")
);

function App() {
  return (
    <>
      <BrowserRouter>
        <Suspense
          fallback={
            <Spinner animation="border" variant="primary" className="m-auto " />
          }
        >
          <Switch>
            <Route path="/donate" component={Donation} />
            <Route
              path="/invitation-orientation"
              component={InvitationOrientationPage}
            />
            <Route
              path="/attendance-orientation"
              component={AttendanceOrientationPage}
            />
            <Route
              path="/invitation-pe"
              component={InvitationProgrammeEventsPage}
            />
            <Route
              path="/attendance-pe"
              component={AttendanceProgrammeEventsPage}
            />
            <Route
              path="/invitation-vr"
              component={InvitationVolunteerRequestPage}
            />
            <Route
              path="/attendance-vr"
              component={AttendanceVolunteerRequestPage}
            />
            <Route path="/question" component={Question} />
            <Route path="/question-result" component={QuestionResult} />
            <Route path="/upload-donation" component={Upload} />
            <Route path="/donation-list" component={DonationList} />
          </Switch>
        </Suspense>
      </BrowserRouter>
    </>
  );
}

export default App;
